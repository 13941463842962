<template>
  <div class="aboutGroup">
    <Head @closeMenu="closeOpenMenu" />
    <Menu ref="menu" />
    <div class="banner">
      <!-- <div class="forward"></div> -->
    </div>
    <div class="contents">
      <div class="contentCenter">
        <h3 class="title">关联企业</h3>
        <p class="lines">
          <span class="lineCenter"></span>
        </p>

        <div class="contextCenter">
          <div class="left"></div>
          <div class="right">
            <h2 class="titles">Affiliated Enterprise</h2>
            <div class="textContent">
               <router-link to="/bjCompanies">
             <p>北京有为信通科技发展有限公司</p>
               </router-link>
                <router-link to="/hzCompanies">
                  <p>杭州卓远数聚科技有限公司</p>
                </router-link>
                <router-link to="/hxCompanies">
                  <p>北京华星数聚智慧科技有限公司</p>
              </router-link>
               <router-link to="/xnCompanies">
              <p>西宁有为信息技术有限公司</p>
               </router-link>
               <router-link to="/xaCompanies">
              <p>西安有为信通软件科技有限公司</p>
                </router-link>
            </div>
            <div class="EnName"></div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <Topreturn @closeMenu="closeOpenMenu"></Topreturn>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Footer from "@/components/footer.vue";
import Menu from "@/components/menu.vue";
import Topreturn from "@/components/Topreturn.vue";
export default {
  components: {
    Head,
    Menu,
    Footer,
    Topreturn
  },
  methods: {
    closeOpenMenu(t) {
      console.log(t);
      this.$refs.menu.menuShow = t;
    },
  },
};
</script>

<style scoped>
/* banner图开始 */
.banner {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/image/ItsCompanies/banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.banner .forward {
  width: 164px;
  height: 44px;
  background-image: url("../../assets/image/aboutGroup/index/BnnerContext.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 58px;
  bottom: 26px;
}
/* banner图结束 */

/* 主体内容开始 */
.contents {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/image/ItsCompanies/mainBackground.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.contents .contentCenter {
  padding-top: 78px;
  color: #fff;
  max-width: 1466px;
  margin: 0 auto;
}
.contents .contentCenter .title {
  font-size: 40px;
  font-family: IOS9W4;
  font-weight: bold;
  color: #17171c;
}
.contents .contentCenter .lines {
  margin-bottom: 36px;
}
.contents .contentCenter .lines .lineCenter {
  width: 70px;
  height: 4px;
  background: #3c4047;
  border-radius: 2px;
  display: inline-block;
}
.contents .contentCenter .contextCenter {
  color: #333333;
  display: flex;
  padding-top: 37px;
  justify-content: space-between;
}
.contents .contentCenter .contextCenter .left {
  width: 577px;
  text-align: left;
  display: inline-block;
  background-image: url("../../assets/image/ItsCompanies/mainContent.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-family: Microsoft YaHei;
  overflow: hidden;
  height: 796px;
}
.contents .contentCenter .contextCenter .right {
  width: 850px;
  display: inline-block;
  text-align: left;
}
.contents .contentCenter .contextCenter .right .titles {
  font-size: 40px;
  font-family: IOS9W4;
  font-weight: 400;
  color: #000508;
  margin-top: 60px;
  margin-left: 34px;
}
.contents .contentCenter .contextCenter .right .textContent {
  padding-top: 89px;
  margin-left: 34px;
  width: 813px;
  /* height: 243px; */
}
.contents .contentCenter .contextCenter .right .textContent p {
  display: inline-block;
  width: 50%;
  font-size: 24px;
  font-family: IOS9W4;
  font-weight: 400;
  color: #00070b;
  line-height: 110px;
}
.contents .contentCenter .contextCenter .right .EnName {
  width: 739px;
  height: 58px;
  background-image: url("../../assets/image/ItsCompanies/mainEnName.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-top: 62px;
  cursor: pointer;
}

/* hover 事件 */
.contents .contentCenter .contextCenter .left:hover {
  background-size: 105% 105%;
  transition: 0.5s;
  
}
.contents .contentCenter .contextCenter .right .textContent p:hover{
  color: #6C35E4;
}
</style>